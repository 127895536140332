import { useTeams } from 'msteams-react-base-component';
import { LogLevel, setLogLevel, setLogFunction } from '@microsoft/teamsfx';

import { useData } from './useData';
import Config from '../Config';
import { ThemeInfoEx } from '../ThemeInfoEx';
import { FluentUiTheme } from '../FluentUiTheme';

// TODO fix this when the SDK stops hiding global state!
let initialized = false;

export function useTeamsFx() {
  const [result] = useTeams({});
  const { error, loading } = useData(async () => {
    if (!initialized) {
      if (Config.nodeEnv === 'development') {
        setLogLevel(LogLevel.Verbose);
        setLogFunction((leve: LogLevel, message: string) => {
          console.log(message);
        });
      }

      initialized = true;
    }
  });

  if (!loading && !error && result.inTeams === undefined) {
    result.inTeams = true;
  }

  const resultData = {
    ...result,
    themeInfo: new ThemeInfoEx(result.theme, result.themeString),
    fluentUiTheme: new FluentUiTheme(result.themeString),
  };

  return { error, loading: loading, ...resultData };
}
